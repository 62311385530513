<template>
	<div>
		<SmsConfirm
			:user="user"
			:type="sms_confirm_type"

			@click="smsCallback"
			@cancel="offSmsConfirm"
		></SmsConfirm>
	</div>
</template>

<script>
	import SmsConfirm from "../Layout/SmsConfirm";
	export default {
		name: 'MainAuth'
		,
		components: {SmsConfirm},
		props: ['user']
		, data: function(){
			return {
				program: {
					name: 'SMS 인증'
					, top: true
				}
				, sms_confirm_type: 'session'
			}
		}

		, methods: {
			smsCallback: function(){

			}
			, offSmsConfirm: function(){

			}
		}
		, created() {
			this.$bus.$emit('onLoad', this.program)
			this.$bus.$emit('')
		}
	}
</script>